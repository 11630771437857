import { render, staticRenderFns } from "./MerchantQuestPage.vue?vue&type=template&id=339a6aeb&scoped=true"
import script from "./MerchantQuestPage.vue?vue&type=script&lang=js"
export * from "./MerchantQuestPage.vue?vue&type=script&lang=js"
import style0 from "./MerchantQuestPage.vue?vue&type=style&index=0&id=339a6aeb&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "339a6aeb",
  null
  
)

export default component.exports