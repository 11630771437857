<template>
  <div class="container mt-10" v-if="isMerchantOnMerchantQuest">
    <div class="quest-header">
      <div class="level-container" style="margin-bottom: 24px;">
        <span class="level-label">Level&nbsp;{{ level }}</span>
      </div>

      <div class="star-box-container">
        <img v-if="!star1" class="quest-star quest-star-level-2" src="@/assets/image/progress-star-inactive.svg">
        <img v-if="star1" class="quest-star quest-star-level-2" src="@/assets/image/progress-star-active.svg">
        <div class="progressbar-line">
          <v-progress-linear v-model="progressBar1" height="7"></v-progress-linear>
        </div>
        <img v-if="!star2" class="quest-star quest-star-level-2" src="@/assets/image/progress-star-inactive.svg">
        <img v-if="star2" class="quest-star quest-star-level-2" src="@/assets/image/progress-star-active.svg">
        <div class="progressbar-line">
          <v-progress-linear v-model="progressBar2" height="7"></v-progress-linear>
        </div>
        <img v-if="!star3" class="quest-star quest-star-level-2" src="@/assets/image/progress-star-inactive.svg">
        <img v-if="star3" class="quest-star quest-star-level-2" src="@/assets/image/progress-star-active.svg">
        <div class="progressbar-line">
          <v-progress-linear v-model="progressBar3" height="7"></v-progress-linear>
        </div>
        <img v-if="!star4" class="quest-star quest-star-level-2" src="@/assets/image/progress-star-inactive.svg">
        <img v-if="star4" class="quest-star quest-star-level-2" src="@/assets/image/progress-star-active.svg">
      </div>

      <div style="font: normal normal normal 20px/24px Roboto;
          letter-spacing: 0px;
          color: #707070;
          opacity: 1;">
        You can avail
      </div>
      <div style="text-align: center;
          font: normal normal medium Roboto;
          font-size: 32px;
          letter-spacing: 0px;
          color: #E2136E;
          opacity: 1;">
        {{ incentivePercantage }} Cashback
      </div>
      <div style="font: normal normal normal 20px/24px Roboto;
          letter-spacing: 0px;
          color: #707070;
          opacity: 1;">
        (upto ৳{{ incentiveMaximum }})
      </div>
    </div>

    <div class="white-box" style="border: 1px solid #E2136E; border-radius: 5px; font-size: 24px;">
      <div v-if="remainingDays > -1">
        <div v-if="level < 4">Process <span style="color: #E2136E;">৳{{ needsToEarnForNextLevel }}</span> payment amount
          more from payment
          links to get to <span style="color: #E2136E;">Level {{ level + 1 }}</span>
        </div>
        <div v-else><span style="color: #E2136E;">Congratulations!</span> You have reached
          <span style="color: #E2136E;">Level {{ level }}</span>
        </div>
        <div v-if="remainingDays>0">Only <span style="color: #E2136E;">{{ remainingDays }} days</span> left!</div>
        <div v-else>Today is the <span style="color: #E2136E;">last day</span> of the campaign!</div>
      </div>
      <div v-else>
        <div>The campaign has concluded. Kindly await for the next '<span style="color: #E2136E;">Quest</span>'.</div>
        <div>Stay connected and continue accepting payments through the Payment Link.</div>
      </div>
    </div>

    <div class="white-box" v-html="faq"></div>
  </div>
</template>

<script>

export default {
  name: "MerchantQuestPage",
  components: {},
  data() {
    return {
      isMerchantOnMerchantQuest: false,
      level: '1',
      howItWorks: "",
      faq: "",
      progressBar1: 0,
      progressBar2: 0,
      progressBar3: 0,
      star1: false,
      star2: false,
      star3: false,
      star4: false,
      incentiveMaximum: 0,
      incentivePercantage: "0%",
      needsToEarnForNextLevel: 0,
      imageLoaded: 0,
      lvlRangeDifference: 0,
      remainingDays: 0,
    };
  },
  mounted() {

  },
  async created() {
    await this.populateMerchantQuestScoreAndMeta();
    this.$feedback.hideLoading();
    this.progressAnimate();
  },
  computed: {},
  methods: {
    progressAnimate() {
      this.progressBarAnim1();
    },
    progressBarAnim1() {
      if (this.level < 1) return;
      setTimeout(() => {
        this.star1 = true;
        if (this.level === 1) {
          this.progressBar1 = 100 * (this.lvlRangeDifference - this.needsToEarnForNextLevel) / this.lvlRangeDifference;
        } else {
          this.progressBar1 += 100;
        }
        console.log(this.level);
        this.progressBarAnim2();
      }, 800)
    },
    progressBarAnim2() {
      if (this.level < 2) return;
      setTimeout(() => {
        this.star2 = true;
        if (this.level === 2) {
          this.progressBar2 = 100 * (this.lvlRangeDifference - this.needsToEarnForNextLevel) / this.lvlRangeDifference;
        } else {
          this.progressBar2 += 100;
        }
        console.log(this.level);
        this.progressBarAnim3();
      }, 800)
    },
    progressBarAnim3() {
      if (this.level < 3) return;
      setTimeout(() => {
        this.star3 = true;
        if (this.level === 3) {
          this.progressBar3 = 100 * (this.lvlRangeDifference - this.needsToEarnForNextLevel) / this.lvlRangeDifference;
        } else {
          this.progressBar3 += 100;
        }
        setTimeout(() => {
          if (this.level === 4) this.star4 = true;
        }, 600)
      }, 800)
    },

    async populateMerchantQuestScoreAndMeta() {
      this.$feedback.showLoading();
      this.isMerchantOnMerchantQuest = false;

      try {
        let response = await this.$cppClient.post('/campaign/quest/query', {})

        let {
          campaignName,
          incentiveMaximum,
          incentivePercantage,
          needsToEarnForNextLevel,
          level,
          campaignDetails,
          upperLimitOfCurrentLevel,
          lowerLimitOfCurrentLevel,
          remainingDays
        } = response.data;

        this.isMerchantOnMerchantQuest = !!campaignName;
        // this.$feedback.hideLoading();
        if (this.isMerchantOnMerchantQuest) {
          this.level = level;
          this.howItWorks = "" //merchantQuestSegment.howItWorks;
          this.faq = ""//merchantQuestSegment.faq;
          this.incentiveMaximum = incentiveMaximum;
          this.incentivePercantage = incentivePercantage;
          this.needsToEarnForNextLevel = needsToEarnForNextLevel;
          this.lvlRangeDifference = upperLimitOfCurrentLevel - lowerLimitOfCurrentLevel;
          this.remainingDays = remainingDays;
          this.faq = campaignDetails;
        } else {
          window.location.href = "/";
        }
      } catch (ex) {
        console.log(ex);
        let {response} = ex;
        this.$feedback.hideLoading();
        await this.$feedback.showFailed(response);
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.container {
  max-width: 1185px;
}

.quest-header {
  background: transparent linear-gradient(107deg, #FFEDAF 0%, #FFF9E3 100%) 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  margin-bottom: 20px;
  height: 354px;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.v-progress-linear {
  overflow: visible;
}

.white-box {
  font-family: Roboto;
  border-radius: 4px;
  box-shadow: 0 0 4px 0 rgb(0 0 0 / 7%);
  background-color: #ffffff;
  padding: 12px;
  margin-bottom: 20px;
}

.white-box--stronger-shadow {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.white-box--title {
  height: 26px;
  font-family: Roboto;
  font-size: 32px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #95989a;
  margin-bottom: 16px;
}

.level-label {
  font-family: Roboto;
  font-size: 40px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #464646;
}

.level-value {
  font-family: Roboto;
  font-size: 40px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #e2136e;
}

.quest-star {
  margin-top: -2px;
  width: 64px;
  height: 64px;
}

.star-box-container {
  display: flex;
  width: 445px;
  align-items: center;
  margin-bottom: 40px;
}

.star-box {
  margin-right: 12px;
}

.star-box:last-child {
  margin-right: 0px;
}

.star-label {
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #777;
  margin-top: 6px;
  margin-bottom: 8px;
}

.progressbar-line {
  width: 66px;
}

@media only screen and (max-width: 1000px) {
  .container {
    margin-top: 75px !important;
  }
}

@media only screen and (max-width: 500px) {
  .star-box-container {
    display: flex;
    justify-content: space-between;
    width: auto;
  }

  .quest-star {
    width: 40px;
    height: 40px;
  }
  .container {
    margin-top: 60px !important;
  }

  .progressbar-line {
    width: 45px;
  }

  .quest-header {
    margin-bottom: 20px;
  }
}
</style>
